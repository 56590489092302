.search-field .search-input {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    z-index: 9999;
}

.search-field .input-group {
    border-radius: 5px;
    z-index: 9999;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1) !important;
}

.search-field .search-list {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: "background.default";
    border: 2px solid #ccc;
    border-top: none;
    overflow: scroll;
    position: absolute;
    z-index: 9999;
    width: 100%;
    max-height: 50vh;
    @media (min-width: 600px) {
        max-height: 70vh;
    }
}